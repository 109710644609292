import { useRecoilState } from "recoil";
import JoinedPlayersView from "../views/JoinedPlayersView";
import { gameOwnerIdAtom, joinedPlayersAtom, tanksAtom } from "../model/Game";
 

function JoinedPlayersPresenter() {

    const [joinedPlayers, setJoinedPlayers] = useRecoilState(joinedPlayersAtom);
    const [gameOwnerId, setGameOwnerId] = useRecoilState(gameOwnerIdAtom);
    const [tanks, setTanks] = useRecoilState(tanksAtom);


    return (<JoinedPlayersView 
        tanks={Object.values(tanks)} gameOwnerId={gameOwnerId} players={joinedPlayers}></JoinedPlayersView>);
}

export default JoinedPlayersPresenter;