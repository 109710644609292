import { useRecoilState } from "recoil";
import { blockSizeAtom, boardColumnsCountAtom, boardMarginLeftAtom, boardMarginTopAtom, boardRowsCountAtom, calculateBoardMarginsAndBlockSize, gameIdAtom } from "../model/Game";
import JoinGameView from "../views/JoinGameView"; 
import { socketAtom } from "../model/User"; 

function JoinGamePresenter() {


    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [boardMarginLeft, setBoardMarginLeft] = useRecoilState(boardMarginLeftAtom);
    const [boardMarginTop, setBoardMarginTop] = useRecoilState(boardMarginTopAtom);
    const [gameId, setGameId] = useRecoilState(gameIdAtom); 
    const [socket, setSocket] = useRecoilState(socketAtom);



    function resizeBlocks() {

        const result = calculateBoardMarginsAndBlockSize(window.innerWidth, window.innerHeight, boardRowsCount, boardColumnsCount)
        setBlockSize(result.blockSize);
        setBoardMarginLeft(result.marginLeft);
        setBoardMarginTop(result.marginTop);
    }


    function onGameIdChanged(event) {
        const value = event.target.value;
        console.log(value)
        try {
            if (!value) { return };
            if (value === NaN) { return };
            const intValue = parseInt(value);
            setGameId(intValue);
            if (intValue < 0) {
                setGameId(0);
            }
            if (intValue > 10000) {
                setGameId(10000);
            }
        } catch (error) {
        }
    }
    async function onJoinGameClicked(event) {
        event.preventDefault();

        console.log("Joining the game!");


        const message = {
            "type": "joinGame",
            "joinGameAccessCode": parseInt(gameId)
        };
        console.log("sending: ", JSON.stringify(message));
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        }

        resizeBlocks();

    }
    return (<JoinGameView
        onGameIdChanged={onGameIdChanged}
        onJoinGameClicked={onJoinGameClicked}

    ></JoinGameView>);
}

export default JoinGamePresenter;