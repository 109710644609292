import { useRecoilState } from "recoil";
import CreateGameView from "../views/CreateGameView";
import { aiCountAtom, blockSizeAtom, boardColumnsCountAtom, boardMarginLeftAtom, boardMarginTopAtom, boardRowsCountAtom, calculateBoardMarginsAndBlockSize, gameModeAtom, isGameCreatedAtom, isGameFinishedAtom, isGameStartedAtom, wallRatioAtom } from "../model/Game";
import { socketAtom } from "../model/User";

function CreateGamePresenter() {

    const [gameMode, setGameMode] = useRecoilState(gameModeAtom);
    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);
    const [aiCount, setAiCount] = useRecoilState(aiCountAtom);
    const [wallRatio, setWallRatio] = useRecoilState(wallRatioAtom);
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [boardMarginLeft, setBoardMarginLeft] = useRecoilState(boardMarginLeftAtom);
    const [boardMarginTop, setBoardMarginTop] = useRecoilState(boardMarginTopAtom);
    const [socket, setSocket] = useRecoilState(socketAtom);

    const [isGameCreated, setIsGameCreated] = useRecoilState(isGameCreatedAtom);
    const [isGameFinished, setIsGameFinished] = useRecoilState(isGameFinishedAtom);
    const [isGameStarted, setIsGameStarted] = useRecoilState(isGameStartedAtom);




    function onGameModeChanged(event) {
        const value = event.target.value;
        setGameMode(value);
    }
    function onRowsChanged(event) {
        const value = event.target.value;
        // console.log(event.target.value);
        try {
            if (!value) { return };
            if (value === NaN) { return };
            const intValue = parseInt(value);
            setBoardRowsCount(intValue);
            if (intValue < 10) {
                setBoardRowsCount(10);
            }
            if (intValue > 100) {
                setBoardRowsCount(100);
            }
        } catch (error) {
        }

    }
    function onColumnsChanged(event) {
        const value = event.target.value;
        // console.log(event.target.value);
        try {
            if (!value) { return };
            if (value === NaN) { return };
            const intValue = parseInt(value);
            setBoardColumnsCount(intValue);
            if (intValue < 10) {
                setBoardColumnsCount(10);
            }
            if (intValue > 100) {
                setBoardColumnsCount(100);
            }
        } catch (error) {
        }


    }

    function onAiCountChanged(event) {
        const value = event.target.value;
        console.log(event.target.value);
        try {
            if (!value) { return };
            if (value === NaN) { return };
            const intValue = parseInt(value);
            setAiCount(intValue);
            if (intValue < 0) {
                setAiCount(0);
            }
            if (intValue > 20) {
                setAiCount(20);
            }
        } catch (error) {
        }

    }
    function onWallRatioChanged(event) {
        const value = event.target.value;
        console.log(event.target.value);
        setWallRatio(parseInt(value));

    }
    function onCreateGameClicked(event) {
        event.preventDefault();
        console.log("creating the game!");
 
        setIsGameCreated(false);
        setIsGameStarted(false);
        setIsGameFinished(false); 

        const message = {
            "type": "createGame",
            "createGameParameters": {
                "gameMode": gameMode,
                "boardRowsCount": parseInt(boardRowsCount),
                "boardColumnsCount": parseInt(boardColumnsCount),
                "aiCount": parseInt(aiCount),
                "wallRatio": parseInt(wallRatio)
            }
        };
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        }


        resizeBlocks();
    }


    function resizeBlocks() {
        const result = calculateBoardMarginsAndBlockSize(window.innerWidth, window.innerHeight, boardRowsCount, boardColumnsCount)
        setBlockSize(result.blockSize);
        setBoardMarginLeft(result.marginLeft);
        setBoardMarginTop(result.marginTop);

    }



    return (<CreateGameView onGameModeChanged={onGameModeChanged}
        onRowsChanged={onRowsChanged}
        onColumnsChanged={onColumnsChanged}
        onAiCountChanged={onAiCountChanged}
        onCreateGameClicked={onCreateGameClicked}
        onWallRatioChanged={onWallRatioChanged}

        rows={boardRowsCount}
        columns={boardColumnsCount}
        aiCount={aiCount}
        wallRatio={wallRatio}
    ></CreateGameView>);
}

export default CreateGamePresenter;