import { useRecoilState } from "recoil";
import { blockSizeAtom, boardColumnsCountAtom, boardRowsCountAtom } from "../model/Game";
import TargetView from "../views/TargetView"; 

function TargetPresenter() {
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom); 
    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);



    let targets = [];

    for (let r = 0; r < boardRowsCount; r++) {
        for (let c = 0; c < boardColumnsCount; c++) {
            targets.push({
                id: r + "-" + c
            })
        }
    }



    function drawTargets(element) {
        return <TargetView key={element.id}
            size={blockSize}
            id={element.id}
        > </TargetView>;

    }

    return (<div>
        {Object.values(targets).map(drawTargets)}
    </div>);
}

export default TargetPresenter;