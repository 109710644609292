import { atom, atomFamily, selector } from "recoil";


export const gameModes = {
    versus: "versus",
    teamVersusTeams: "teamVersusTeams",
    teamsVersusAI: "teamsVersusAI",
}

export const elementTypes = {
    space: 's',
    wall: 'w',
    target: 'x',
    tank: 't',
    weapon: 'e',
    bullet: 'b',
    distructed: 'd',
    artifact: 'a',
}
export const artifactTypes = {
    tank: "tank",
    world: "world",
}

export const orientations = {
    up: 'u',
    down: 'd',
    left: 'l',
    right: 'r',
}

export const artifactExpiry = 10;



export const newlyCreatedGamesAtom = atom({
    key: 'newlyCreatedGamesAtom',
    default: [],
});

export const acheivedArtifactsAtom = atom({
    key: 'acheivedArtifactsAtom',
    default: [],
});

export const timeBetweenActionsAtom = atom({
    key: 'timeBetweenActionsAtom',
    default: 120,
});
export const actionStartedAtom = atom({
    key: 'actionStartedAtom',
    default: false,
});
export const shootActionAtom = atom({
    key: 'shootActionAtom',
    default: "",
});

export const moveActionAtom = atom({
    key: 'moveActionAtom',
    default: "",
});
export const gameSpeedAtom = atom({
    key: 'gameSpeedAtom',
    default: 1,
});

export const boardRowsCountAtom = atom({
    key: 'boardRowsCountAtom',
    default: 20,
});
export const boardColumnsCountAtom = atom({
    key: 'boardColumnsCountAtom',
    default: 30,
});


export const boardMarginLeftAtom = atom({
    key: 'boardMarginLeftAtom',
    default: 0,
});
export const boardMarginTopAtom = atom({
    key: 'boardMarginTopAtom',
    default: 0,
});

export const blockSizeAtom = atom({
    key: 'blockSizeAtom',
    default: 48,
});


export const artifactsAtom = atom({
    key: 'artifactsAtom',
    default: {},
});
export const artifactsAtomFamily = atomFamily({
    key: 'artifactsAtomFamily',
    default: undefined,
});

export const artifactsAtomSelector = selector({
    key: 'artifactsAtomSelector',
    get: ({ get }) => {
        return null;
    },
    set: ({ set, get }, { id, element }) => {
        if (id) {
            // console.log("artifact atom famili: setting id: ", id, " to value ", element);
            set(artifactsAtomFamily(id), element);
        }
    }
});


export const wallsAtom = atom({
    key: 'wallsAtom',
    default: {},
});

export const wallsAtomFamily = atomFamily({
    key: 'wallsAtomFamily',
    default: undefined,
});

export const wallsAtomSelector = selector({
    key: 'wallsAtomSelector',
    get: ({ get }) => {
        return null;
    },
    set: ({ set, get }, { id, element }) => {
        if (id) {
            // console.log("wall atom famili: setting id: ", id, " to value ", element);
            set(wallsAtomFamily(id), element);
        }
    }
});



export const tanksAtom = atom({
    key: 'tanksAtom',
    default: {},
});


export const tankPositionAtom = atom({
    key: 'tankPositionAtom',
    default: {
        row: 0,
        column: 0
    },
});


export const tanksAtomFamily = atomFamily({
    key: 'tanksAtomFamily',
    default: undefined,
});

export const tanksAtomSelector = selector({
    key: 'tanksAtomSelector',
    get: ({ get }) => {
        return null;
    },
    set: ({ set, get }, { id, element }) => {
        if (id) {
            // console.log("tank atom famili: setting id: ", id, " to value ", element);
            set(tanksAtomFamily(id), element);
        }
    }
});


export const maskRadiusAtom = atom({
    key: 'maskRadiusAtom',
    default: 200,
});

export const freezeTimeAtom = atom({
    key: 'freezeTimeAtom',
    default: 0,
});

export const artifactEnablerIdAtom = atom({
    key: 'artifactEnablerIdAtom',
    default: 0,
});


export const targetsAtom = atom({
    key: 'targetsAtom',
    default: {},
});

export const targetsAtomFamily = atomFamily({
    key: 'targetsAtomFamily',
    default: undefined,
});

export const targetsAtomSelector = selector({
    key: 'targetsAtomSelector',
    get: ({ get }) => {
        return null;
    },
    set: ({ set, get }, { id, element }) => {
        if (id) {
            // console.log("target atom famili: setting id: ", id, " to value ", element);
            set(targetsAtomFamily(id), element);
        }
    }
});

export const bulletsAtom = atom({
    key: 'bulletsAtom',
    default: {},
});

export const bulletsAtomFamily = atomFamily({
    key: 'bulletsAtomFamily',
    default: undefined,
});

export const bulletsAtomSelector = selector({
    key: 'bulletsAtomSelector',
    get: ({ get }) => {
        return null;
    },
    set: ({ set, get }, { id, element }) => {
        if (id) {
            // console.log("bullet atom famili: setting id: ", id, " to value ", element);
            set(bulletsAtomFamily(id), element);
        }
    }
});


export const distructedsAtom = atom({
    key: 'distructedsAtom',
    default: {},
});


export const gameOwnerIdAtom = atom({
    key: 'gameOwnerIdAtom',
    default: 0,
});
export const gameModeAtom = atom({
    key: 'gameModeAtom',
    default: 0,
});
export const aiCountAtom = atom({
    key: 'aiCountAtom',
    default: 0,
});

export const wallRatioAtom = atom({
    key: 'wallRatioAtom',
    default: 20,
});

export const gameIdAtom = atom({
    key: 'gameIdAtom',
    default: 0,
});
export const gameAccessCodeAtom = atom({
    key: 'gameAccessCodeAtom',
    default: 0,
});
export const isGameCreatedAtom = atom({
    key: 'isGameCreatedAtom',
    default: false,
});
export const isGameStartedAtom = atom({
    key: 'isGameStartedAtom',
    default: false,
});
export const isGameFinishedAtom = atom({
    key: 'isGameFinishedAtom',
    default: false,
});
export const gameWinnersAtom = atom({
    key: 'gameWinnersAtom',
    default: [],
});
export const gameLosersAtom = atom({
    key: 'gameLosersAtom',
    default: [],
});
export const joinedPlayersAtom = atom({
    key: 'joinedPlayersAtom',
    default: [],
});

export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export function getInfrontPostion(element) {

    let elementPosR = 0;
    let elementPosC = 0;
    switch (element.orientation) {
        case orientations.up:
            elementPosR = element.position.row - 1;
            elementPosC = element.position.column;
            break;

        case orientations.down:
            elementPosR = element.position.row + 1;
            elementPosC = element.position.column;
            break;

        case orientations.left:
            elementPosR = element.position.row;
            elementPosC = element.position.column - 1;
            break;
        case orientations.right:
            elementPosR = element.position.row;
            elementPosC = element.position.column + 1;
            break;
    }

    return {
        row: elementPosR,
        column: elementPosC,
    }
}

export function getLocations(numOfPlayers, boardRowsCount, boardColumnsCount) {


    switch (numOfPlayers) {
        case 0:
            return {
                target: {
                    row: 0,
                    column: 0
                },
                tank: {
                    row: 2,
                    column: 2
                }
            };
        case 1:
            return {
                target: {
                    row: boardRowsCount - 1,
                    column: boardColumnsCount - 1,
                },
                tank: {
                    row: boardRowsCount - 3,
                    column: boardColumnsCount - 3
                }
            }
        case 2:
            return {
                target: {
                    row: boardRowsCount - 1,
                    column: 0,
                },
                tank: {
                    row: boardRowsCount - 3,
                    column: 2
                }
            }
        case 3:
            return {
                target: {
                    row: 0,
                    column: boardColumnsCount - 1,
                },
                tank: {
                    row: 2,
                    column: boardColumnsCount - 3
                }
            }
        default:
            return {
                target: {
                    row: Math.floor(Math.random() * (boardRowsCount - 1)),
                    column: Math.floor(Math.random() * (boardColumnsCount - 1)),
                },
                tank: {
                    row: Math.floor(Math.random() * (boardRowsCount - 1)),
                    column: Math.floor(Math.random() * (boardColumnsCount - 1))
                }
            }
    }


}

export function createGame(map) {

    // const map = maps.map2;
    // console.log(map);
    const boardRows = map.elements.length;
    const boardColumns = map.elements[0].length;
    let board = Array.from(Array(boardRows), () => new Array(boardColumns));
    for (let r = 0; r < boardRows; r++) {
        const row = map.elements[r];
        for (let c = 0; c < boardColumns; c++) {
            const pos = (r * boardColumns) + c;
            if (!row) { continue; }

            let element = map[row.charAt(c)];
            element = {
                ...element,
                id: r + "-" + c + "-" + Math.ceil(Math.random() * 1000),
                position: {
                    r, c
                }
            };
            board[pos] = element;
        }
    }




    return {
        boardSize: {
            rows: boardRows,
            columns: boardColumns,
        },
        board,
    }
}
export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export function calculateBoardMarginsAndBlockSize(innerWidth, innerHeight, boardRowsCount, boardColumnsCount) {

    const width = innerWidth;
    const height = innerHeight - 200;

    let blockSize = Math.min(height / boardRowsCount, width / boardColumnsCount)
    blockSize = Math.floor(blockSize);

    const boardWidthInPixels = blockSize * boardColumnsCount;
    const extraWidthSpaceInPixels = width - boardWidthInPixels;
    const marginLeft = (extraWidthSpaceInPixels / 2);

    const boardHeightInPixels = blockSize * boardRowsCount;
    const extraHeightSpaceInPixels = height - boardHeightInPixels;
    const marginTop = (extraHeightSpaceInPixels / 2) + 10;

    return {
        marginTop, marginLeft, blockSize
    };
}