import { atom } from "recoil";


export const playerElementsIdAtom = atom({
    key: 'playerElementsIdAtom',
    default: 0,
});

export const playerEmailAtom = atom({
    key: 'playerEmailAtom',
    default: "",
});

export const playerPasswordAtom = atom({
    key: 'playerPasswordAtom',
    default: "",
});

export const playerNameAtom = atom({
    key: 'playerNameAtom',
    default: "",
});

export const isPlayerLoggedInAtom = atom({
    key: 'isPlayerLoggedInAtom',
    default: false,
});


export const socketAtom = atom({
    key: 'socketAtom',
    default: null,
});


export const pingIntervalAtom = atom({
    key: 'pingIntervalAtom',
    default: null,
});
export const pingIntervalTimeAtom = atom({
    key: 'pingIntervalTimeAtom',
    default: 30000,
});


export const apiGoogleSignInUrlAtom = atom({
    key: 'apiGoogleSignInUrlAtom',
    default: "https://server.rabbheat.com/tanks/auth/signin/google/",
//     default: "https://localhost:7000/signin/google/",
});

export const websocketUrlAtom = atom({
    key: 'websocketUrlAtom',
    default: "wss://server.rabbheat.com/tanks/api/ws/connect",
//     default: "wss://localhost:7001/connect",
});

export const userAccessTokenAtom = atom({
    key: 'userAccessTokenAtom',
    default: null,
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI3Nzg2NzYsInByb2ZpbGVJZCI6IjJkZDlhMWQ4LTQwODEtNGNkOC1iZWU0LWRkMzE1ZWYwZDhkNyIsIm5iZiI6MTY3Mjc3ODY3NiwiZXhwIjoxNjczMzc4Njc2fQ.Qqgxg9LMPhfvVeVkVoQu3nVKc5PNzT0-_95P81Gy0-k",
});
export const googleAccessTokenAtom = atom({
    key: 'googleAccessTokenAtom',
    default: null,
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI3Nzg2NzYsInByb2ZpbGVJZCI6IjJkZDlhMWQ4LTQwODEtNGNkOC1iZWU0LWRkMzE1ZWYwZDhkNyIsIm5iZiI6MTY3Mjc3ODY3NiwiZXhwIjoxNjczMzc4Njc2fQ.Qqgxg9LMPhfvVeVkVoQu3nVKc5PNzT0-_95P81Gy0-k",
});
export const didReceiveGoogleAccessTokenAtom = atom({
    key: 'didReceiveGoogleAccessTokenAtom',
    default: false,
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI3Nzg2NzYsInByb2ZpbGVJZCI6IjJkZDlhMWQ4LTQwODEtNGNkOC1iZWU0LWRkMzE1ZWYwZDhkNyIsIm5iZiI6MTY3Mjc3ODY3NiwiZXhwIjoxNjczMzc4Njc2fQ.Qqgxg9LMPhfvVeVkVoQu3nVKc5PNzT0-_95P81Gy0-k",
});

export const userRefreshTokenAtom = atom({
    key: 'userRefreshTokenAtom',
    default: null,
});


