import { useRecoilState } from "recoil";
import { wallsAtomFamily } from "../model/Game";

function WallView({ id, size }) {

    const [wall, setWall] = useRecoilState(wallsAtomFamily(id));

    if (wall === undefined) {
        return <div></div>;
    }
    // else {
    // console.log("rendering wall: ", wall);
    // }

    const opacity = (wall.maxHealth - wall.damageTaken) / wall.maxHealth;
    const image = process.env.PUBLIC_URL + "images/" + wall.image;
    return (
        <div className="wall" style={{
            top: (wall.position.row * size) + "px",
            left: (wall.position.column * size) + "px",
            width: size + "px",
            height: size + "px",
            opacity: opacity < 0 ? 0 : opacity + 0.3,
            backgroundImage: "url(" + image + ")",
            // WebkitBoxShadow: "2px 2px 6px 0.51px " + wall.color + "50",
            // boxShadow: "2px 2px 6px 0.51px " + wall.color + "50",
        }} >

        </div>
    );
}

export default WallView;