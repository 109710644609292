import { useRecoilState } from "recoil";
import { artifactsAtom, artifactsAtomSelector, boardColumnsCountAtom, boardRowsCountAtom, bulletsAtom, bulletsAtomSelector, distructedsAtom, elementTypes, gameAccessCodeAtom, gameIdAtom, gameModeAtom, isGameCreatedAtom, isGameFinishedAtom, isGameStartedAtom, joinedPlayersAtom, artifactEnablerIdAtom, maskRadiusAtom, newlyCreatedGamesAtom, tanksAtom, targetsAtom, targetsAtomSelector, timeBetweenActionsAtom, wallsAtom, wallsAtomSelector, freezeTimeAtom, tankPositionAtom } from "../model/Game";
import { playerElementsIdAtom, socketAtom } from "../model/User";
import { useEffect } from "react";
import { getNewWall } from "../model/Elements";

function WebsocketParserPresenter() {

    const [socket, setSocket] = useRecoilState(socketAtom);
    const [gameMode, setGameMode] = useRecoilState(gameModeAtom);
    const [gameAccessCode, setGameAccessCode] = useRecoilState(gameAccessCodeAtom);
    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);
    const [gameId, setGameId] = useRecoilState(gameIdAtom);
    const [playerElementsId, setPlayerElementsId] = useRecoilState(playerElementsIdAtom);
    const [tankPosition, setTankPosition] = useRecoilState(tankPositionAtom);

    const [isGameCreated, setIsGameCreated] = useRecoilState(isGameCreatedAtom);
    const [isGameFinished, setIsGameFinished] = useRecoilState(isGameFinishedAtom);
    const [isGameStarted, setIsGameStarted] = useRecoilState(isGameStartedAtom);
    const [joinedPlayers, setJoinedPlayers] = useRecoilState(joinedPlayersAtom);
    const [tanks, setTanks] = useRecoilState(tanksAtom);
    const [bullets, setBullets] = useRecoilState(bulletsAtom);
    const [distructeds, setDistructeds] = useRecoilState(distructedsAtom);
    const [bulletSelector, setBulletSelector] = useRecoilState(bulletsAtomSelector);
    const [wallSelector, setWallSelector] = useRecoilState(wallsAtomSelector);
    const [artifactSelector, setArtifactSelector] = useRecoilState(artifactsAtomSelector);
    const [timeBetweenActions, setTimeBetweenActions] = useRecoilState(timeBetweenActionsAtom);
    const [targetSelector, setTargetSelector] = useRecoilState(targetsAtomSelector);
    const [maskRadius, setMaskRadius] = useRecoilState(maskRadiusAtom);
    const [freezeTime, setFreezeTime] = useRecoilState(freezeTimeAtom);
    const [artifactEnablerId, setArtifactEnablerId] = useRecoilState(artifactEnablerIdAtom);
//     const [newlyCreatedGames, setNewlyCreatedGames] = useRecoilState(newlyCreatedGamesAtom);


    useEffect(() => {

        if (socket) {
            socket.binaryType = "arraybuffer";

            socket.onmessage = function (event) {
                const binaryData = event.data;  // binaryData is an ArrayBuffer object
                const decoder = new TextDecoder();
                const jsonString = decoder.decode(binaryData);
                const message = JSON.parse(jsonString);
                // console.log("Received message is:", message);

                switch (message.type) {
                    case 'c':
                        console.log("Received elements id:", message);
                        setPlayerElementsId(message.elementsId);
                        break;
                    case 'g':
                        // console.log("Received game:", message.game);
                        setGame(message.game);
                        break;

                    case 'u':
                        updateGame(message.updatedStationaryElements, message.deletedStationaryElements, message.tanks, message.bullets);
                        break;
                    case 't':
                        // updateGame(message.updatedElements, message.removedPositions);
                        updateTanks(message.tanks);
                        break;
                          case 'p':
                        console.log("Received player:", message);
                                    
                        updatePlayers(message.joinedPlayers);
                        break;

                    case 'x':
                        // updateGame(message.updatedElements, message.removedPositions);
                        updateTargets(message.targets);
                        break;

                    case 'm':
                        console.log("Received mask:", message);
                        setArtifactEnablerId(message.artifactEnablerId);
                        if (message.artifactEnablerId != playerElementsId) {
                                  setMaskRadius(message.maskRadius);
                            setTimeout(() => {
                                setArtifactEnablerId(0);
                                setMaskRadius(200);
                            }, 10000);
                        }
                                    break;
                          
                    case 'f':
                                    console.log("Received freeze:", message); 
                                    console.log(" artiface enabler id: ", message.artifactEnablerId, "   - playerElementsid: " + playerElementsId);
                        
                        
                        console.log("Received mask:", message);
                        setArtifactEnablerId(message.artifactEnablerId);
                        if (message.artifactEnablerId != playerElementsId) {
                                  setFreezeTime(message.time);
                            setTimeout(() => {
                                setArtifactEnablerId(0);
                                setFreezeTime(0);
                            }, 10000);
                        }
                                    break;
                        
                    //                 setArtifactEnablerId(message.artifactEnablerId);
                    // if (message.artifactEnablerId != playerElementsId) {
                    //     setFreezeTime((preFreezeTime) => { return message.time });  
                    //           var timer = setInterval(function() { 
                    //                     setFreezeTime((preFreezeTime) => { 
                    //                               if (preFreezeTime<1) { 
                    //                                         clearInterval(timer);
                    //                                         return 0;
                    //                               } 
                    //                               return preFreezeTime - 1;
                    //                     });
                    //           }, 1000);
                    //     }
                    //     break;

                    // case 'n':
                    //     console.log("New game created, Access code:", message.accessCode);
                    //                 let alreadyCreatedGames = [...newlyCreatedGames];
                    //                 alreadyCreatedGames.push(message.accessCode);
                    //                 setNewlyCreatedGames(alreadyCreatedGames);

                    //     break;

                }
            }
        }
    }, [socket,playerElementsId]);//remove?




    function updateTanks(tanks) {


        let tempTanks = {}


        for (let r = 0; r < tanks.length; r++) {
            const element = tanks[r]; 
            tempTanks[element.id] = element;
        }



        setTanks(tempTanks);

    }


    function updatePlayers(joinedPlayers) {

        if (joinedPlayers) {
            setJoinedPlayers(joinedPlayers);
        }
        // if (game.joinedPlayers[0]) {
        //     setTimeBetweenActions(game.joinedPlayers[0].timeBetweenActions);
        // }


    }


    function updateTargets(targets) {

        if (targets) {
            for (let t = 0; t < targets.length; t++) {
                const element = targets[t];

                if (element.damageTaken >= element.maxHealth) {
                    setTargetSelector({ id: (element.position.row + "-" + element.position.column), element: undefined });
                } else {
                    setTargetSelector({ id: (element.position.row + "-" + element.position.column), element });
                }

            }
        }
    }







    function updateGame(updatedStationaryElements, deletedStationaryElements, tanks, bullets) {

        let tempTanks = {}
        let updateTanks = false;
        let tempBullets = {}
        // let updateBullets = false;
        let tempDistructeds = {};
        let updateDistructeds = false;

        if (tanks) {
            for (let t = 0; t < tanks.length; t++) {
                tempTanks[tanks[t].id] = tanks[t];
            }
        }

        if (bullets) {
            for (let t = 0; t < bullets.length; t++) {
                tempBullets[bullets[t].id] = bullets[t];
                // const element = bullets[t];
                // setBulletSelector({ id: (element.position.row + "-" + element.position.column), element });
            }
        }


        if (deletedStationaryElements) {
            for (let t = 0; t < deletedStationaryElements.length; t++) {
                const element = deletedStationaryElements[t];
                // setTargetSelector({ id: (element.row + "-" + element.column), element: undefined });
                // setWallSelector({ id: (element.row + "-" + element.column), element: undefined });
                // for removing artifacts
                // const shootAudio = process.env.PUBLIC_URL + "sounds/weaponCollect.wav";
                // console.log("playing: ", shootAudio)
                // const shootAudioPlayer = new Audio(shootAudio);
                // shootAudioPlayer.volume = 0.05;
                // shootAudioPlayer.play();
                setArtifactSelector({ id: (element.row + "-" + element.column), element: undefined });
                tempDistructeds[(element.row + "-" + element.column)] = getNewWall({ position: element });
                updateDistructeds = true;
                // switch (element.type) {
                //     case 'x':
                //         break;
                //     case 'w':
                //         break;
                //     case 'a':
                //         break;

                //     default:
                //         break;
                // }

            }
        }


        if (updatedStationaryElements) {
            for (let t = 0; t < updatedStationaryElements.length; t++) {
                const element = updatedStationaryElements[t];
                switch (element.type) {
                    case 'x':
                        if (element.damageTaken >= element.maxHealth) {
                            setTargetSelector({ id: (element.position.row + "-" + element.position.column), element: undefined });
                        } else {
                            setTargetSelector({ id: (element.position.row + "-" + element.position.column), element });
                        }
                        break;
                    case 'w':
                        if (element.damageTaken >= element.maxHealth) {
                            setWallSelector({ id: (element.position.row + "-" + element.position.column), element: undefined });
                            // const shootAudio = process.env.PUBLIC_URL + "sounds/wallDestroy.mp3";
                            // console.log("playing: ", shootAudio)
                            // const shootAudioPlayer = new Audio(shootAudio);
                            // shootAudioPlayer.volume = 0.05;
                            // shootAudioPlayer.play();
                        } else {
                            setWallSelector({ id: (element.position.row + "-" + element.position.column), element });
                            // const shootAudio = process.env.PUBLIC_URL + "sounds/click.wav";
                            // console.log("playing: ", shootAudio)
                            // const shootAudioPlayer = new Audio(shootAudio);
                            // shootAudioPlayer.volume = 0.05;
                            // shootAudioPlayer.play();
                        }
                        break;
                    case 'a':
                        if (element.damageTaken >= element.maxHealth) {
                            const shootAudio = process.env.PUBLIC_URL + "sounds/weaponCollect.wav";
                            console.log("playing: ", shootAudio)
                            const shootAudioPlayer = new Audio(shootAudio);
                            shootAudioPlayer.volume = 0.05;
                            shootAudioPlayer.play();
                            setArtifactSelector({ id: (element.position.row + "-" + element.position.column), element: undefined });
                        } else {
                            // const shootAudio = process.env.PUBLIC_URL + "sounds/weaponCollect.wav";
                            // console.log("playing: ", shootAudio)
                            // const shootAudioPlayer = new Audio(shootAudio);
                            // shootAudioPlayer.volume = 0.05;
                            // shootAudioPlayer.play();
                            setArtifactSelector({ id: (element.position.row + "-" + element.position.column), element });
                        }
                        break;

                    default:
                        break;
                }

                if (element.damageTaken >= element.maxHealth) {
                    tempDistructeds[(element.position.row + "-" + element.position.column)] = element;
                    updateDistructeds = true;
                }
            }
        }




        // }

        if (updateDistructeds) {
            setDistructeds(tempDistructeds);
        }

        // if (updateTanks) {
        setTanks(tempTanks);
        // }
        // if (updateBullets) {
        setBullets(tempBullets);
        // }

    }



    function setGame(game) {



        // public int ArtifactExpiryTime { get; set; } = 10;
        // public int TimeBetweenActions { get; set; } = 100;
        // public int GameSpeed { get; set; } = 1; 

        let tempTanks = {}
        // const tempTargets = {}
        // const tempWalls = {}
        // const tempBullets = {}
        // const tempArtifacts = {}

        for (let r = 0; r < game.boardRowsCount; r++) {
            for (let c = 0; c < game.boardColumnsCount; c++) {
                setTargetSelector({ id: (r + "-" + c), undefined });
                setWallSelector({ id: (r + "-" + c), undefined });
                setArtifactSelector({ id: (r + "-" + c), undefined });

            }

        }


        for (let r = 0; r < game.stationaryElements.length; r++) {
            const row = game.stationaryElements[r];
            for (let c = 0; c < row.length; c++) {
                const element = row[c];
                if (!element) { continue; }
                switch (element.type) {
                    case elementTypes.target:
                        setTargetSelector({ id: (element.position.row + "-" + element.position.column), element });
                        // tempTargets[element.id] = element;
                        break;
                    case 'w':
                        setWallSelector({ id: (element.position.row + "-" + element.position.column), element });
                        // tempWalls[element.id] = element; 
                        break;
                    case elementTypes.artifact:
                        setArtifactSelector({ id: (element.position.row + "-" + element.position.column), element });
                        // tempArtifacts[element.id] = element;
                        break;

                    default:
                        break;
                }

            }

        }


        for (let r = 0; r < game.bullets.length; r++) {
            const element = game.bullets[r];
            setBulletSelector({ id: (element.position.row + "-" + element.position.column), element });
        }


        for (let r = 0; r < game.tanks.length; r++) {
            const element = game.tanks[r];
            tempTanks[element.id] = element;
        }




        setGameId(game.id);
        setGameMode(game.gameMode);
        setIsGameCreated(true);
        setIsGameStarted(game.isGameStarted);
        setIsGameFinished(game.isGameFinished);
        setJoinedPlayers(game.joinedPlayers);
        setBoardRowsCount(game.boardRowsCount);
        setBoardColumnsCount(game.boardColumnsCount);
        if (game.joinedPlayers[0]) {
            setTimeBetweenActions(game.joinedPlayers[0].timeBetweenActions);
        }
        setTanks(tempTanks);
        // setTargets(tempTargets);
        // setWalls(tempWalls);
        // setBullets(tempBullets);
        // setArtifacts(tempArtifacts); 
        setGameAccessCode(game.accessCode);

    }




    return (<div></div>);
}

export default WebsocketParserPresenter;