import { useRecoilState } from "recoil";
import { blockSizeAtom, distructedsAtom } from "../model/Game";
import DistructedView from "../views/DistructedView";

function DistructedPresenter() {
    const [distructeds, setDistructeds] = useRecoilState(distructedsAtom);
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);

 

    function drawDistructed(element) {

        const key = Math.ceil(Math.random() * 10000);
        return <DistructedView key={key}
            element={element}
            size={blockSize}
        ></DistructedView>;

    }
    return (<div>
        {Object.values(distructeds).map(drawDistructed)}</div>);
}

export default DistructedPresenter;