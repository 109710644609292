function NewlyCreatedGamesView({ accessCodes, onGameIdClicked }) {

    function NewlyCreatedGamesView(accessCode) {
        return <button key={"b" + accessCode} onClick={(event) => { onGameIdClicked(event, accessCode); }} style={{ width: "100%" }}>
            {accessCode}
        </button>;
    }
 
    return (<form>
        <div key={"newlyCreatedGamesListKey2"} style={{ fontSize: "40px", margin:"5px" }}>Newly Created</div>
        <div key={"newlyCreatedGamesListKey"}>
            {accessCodes.map(NewlyCreatedGamesView)}
        </div>
    </form>);
}

export default NewlyCreatedGamesView;