import { useRecoilState } from "recoil";
import { blockSizeAtom, bulletsAtom} from "../model/Game";
import BulletView from "../views/BulletView";

function BulletPresenter() {
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [bullets, setBullets] = useRecoilState(bulletsAtom);



    function drawBullets(element) {
        // console.log("drawing bullets:", element);
        return <BulletView key={element.id}
            // id={element.id}
            bullet={element}
            size={blockSize}
        ></BulletView>;

    }


    return (<div>
        {Object.values(bullets).map(drawBullets)}
    </div>);
}

export default BulletPresenter;