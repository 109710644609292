import { useRecoilState } from "recoil";
import WeaponsIconsPresenter from "../presenter/WeaponsIconPresenter";

function JoinedPlayersView({ tanks, players, gameOwnerId }) {

    function showPlayerNames(player) {
        let playerTank;
        for (let i = 0; i < tanks.length; i++) {
            const element = tanks[i];
            if (element.ownerId === player.elementsId) {
                playerTank = element; 
                break; 
            }
        }

        const image = process.env.PUBLIC_URL + "images/" + (playerTank ? playerTank.bullet.image : "");
        const heartImage = process.env.PUBLIC_URL + "images/heart.svg";

        return <div key={player.elementsId} className={"joined-player " + (player.isAlive ? "" : "line-through")} style={{
            background: `linear-gradient(${player.color}, ${player.color + "c0"})`,

            WebkitBoxShadow: "0px 0px 93px 4px " + player.color,
            boxShadow: "0px 0px 93px 4px " + player.color
        }}>
            <div>{player.id === gameOwnerId ? "👑" : ""} {player.name} </div>
            <div className="joined-players-details-holder">
                <div className="joined-players-details-item">
                    <div className="joined-players-details-image"></div>
                    <div>{(player.maxLivesCount - player.deathCount)}/{player.maxLivesCount} </div>
                </div>

                <div className="joined-players-details-item">
                    <div className="joined-players-details-image" style={{
                        backgroundImage: "url(" + image + ")",
                    }}></div>
                    <div>{playerTank ? playerTank.ammo : 0}</div>
                </div>

                <div className="joined-players-details-item">
                    <div className="joined-players-details-image" style={{
                        backgroundImage: "url(" + heartImage + ")",
                    }}></div>
                    <div>{playerTank ? playerTank.maxHealth - playerTank.damageTaken : 0}</div>
                </div>

            </div>
            <WeaponsIconsPresenter player={player}></WeaponsIconsPresenter>
        </div>
    }





    return (<div className="joined-players-holder">

        <div className="flex-row">
            <div style={{ color: "white", padding: "0px 10px" }}>Joined Players:</div>
        </div>
        {players.map(showPlayerNames)}
    </div>);
}

export default JoinedPlayersView;