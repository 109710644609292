import WeaponsIconsView from "../views/WeaponsIconsView";

function WeaponsIconsPresenter({ player }) {
 

    // console.log("the player: ", player);
    function drawWeapons(tank) {
        console.log("drawing weapon; ",tank.bullet);
        return <WeaponsIconsView key={tank.weapon.id}
            weapon={tank.bullet}
        ></WeaponsIconsView>;
    }


    return (<div className="flex-row">
        {/* {Object.values(tanks).filter((t) => t.ownerId === player.id).map(drawWeapons)}  */}
    </div>);
}

export default WeaponsIconsPresenter;