import { useRecoilState } from "recoil";
import SignInView from "../views/SignInView";
import { playerEmailAtom, playerPasswordAtom } from "../model/User";

function SignInPresenter() {

    const [playerEmail, setPlayerEmail] = useRecoilState(playerEmailAtom);
    const [playerPassword, setPlayerPassword] = useRecoilState(playerPasswordAtom);

    function onSignInClicked(event) {
        event.preventDefault();
    }

    function onEmailChanged(event) {
        console.log(event.target.value);
        setPlayerEmail(event.target.value);
    }
    function onPasswordChanged(event) {
        console.log(event.target.value);
        setPlayerPassword(event.target.value);
    }


    return (<SignInView onSignInClicked={onSignInClicked}
        onPasswordChanged={onPasswordChanged}
        onEmailChanged={onEmailChanged}
    ></SignInView>);
}

export default SignInPresenter;