function DistructedView({ element, size }) {
    return (<div>
        <div key={element.id} className="distructed animate__animated animate__zoomOut" style={{
            top: (element.position.row * size) + "px",
            left: (element.position.column * size) + "px",
            width: size + "px",
            height: size + "px"
        }}>{element.name}</div>
    </div>);
}

export default DistructedView;