import { useRecoilState } from "recoil";
import HeaderView from "../views/HeaderView";
import { actionStartedAtom, artifactEnablerIdAtom, freezeTimeAtom, isGameCreatedAtom, isGameFinishedAtom, isGameStartedAtom, shootActionAtom } from "../model/Game";
import { useEffect } from "react";
import { playerElementsIdAtom } from "../model/User";

function HeaderPresenter() { 

    const [isGameCreated, setIsGameCreated] = useRecoilState(isGameCreatedAtom);
    const [isGameFinished, setIsGameFinished] = useRecoilState(isGameFinishedAtom);
    const [isGameStarted, setIsGameStarted] = useRecoilState(isGameStartedAtom);
    const [actionStarted, setActionStarted] = useRecoilState(actionStartedAtom);
    const [action, setAction] = useRecoilState(shootActionAtom);
    const [freezeTime, setFreezeTime] = useRecoilState(freezeTimeAtom); 
    const [artifactEnablerId, setArtifactEnablerId] = useRecoilState(artifactEnablerIdAtom);
    const [playerElementsId, setPlayerElementsId] = useRecoilState(playerElementsIdAtom);




          useEffect(() => {
              
                    if (freezeTime > 0 ) {
                                   setTimeout(() => {
                                             setFreezeTime(freezeTime-1);
                   }, 1000);
                    }

        function handleKeydown(event) {
                  if (freezeTime > 0 && playerElementsId != artifactEnablerId) {
              
                              return;
                    }
              

            if (event.key === ' ') {
                setAction("shoot");
            } else if (event.code === 'ArrowUp') {
                setAction("up");
            } else if (event.key === 'ArrowDown') {
                setAction("down");
            } else if (event.key === 'ArrowLeft') {
                setAction("left");
            } else if (event.key === 'ArrowRight') {
                setAction("right");
            } else {
                setAction("");
            }

            setActionStarted(true);

        }

        function handleKeyup() {
            setActionStarted(false);
        }
        function handleKeypress() {
            // setActionStarted(false);
        }

        window.addEventListener('keyup', handleKeyup);
        window.addEventListener('keydown', handleKeydown);
        window.addEventListener('keypress', handleKeypress);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('keyup', handleKeyup);
            window.removeEventListener('keydown', handleKeydown);
            window.removeEventListener('keypress', handleKeypress);
        };
    }, [freezeTime,playerElementsId,artifactEnablerId]);





    return (<HeaderView
        isGameCreated={isGameCreated}
        isGameFinished={isGameFinished}
    ></HeaderView>);
}

export default HeaderPresenter;