import { useRecoilState } from "recoil";
import { targetsAtomFamily } from "../model/Game";

function TargetView({ id, size }) {

    const [target, setTarget] = useRecoilState(targetsAtomFamily(id));

    if (!target) {
        return <div></div>;
    }
    const image = process.env.PUBLIC_URL + "images/" + target.image;
    return (<div>
        <div className="target" style={{
            top: (target.position.row * size) + "px",
            left: (target.position.column * size) + "px",
            width: size + "px",
            height: size + "px",
            // backgroundColor: target.color,
            backgroundImage: "url(" + image + ")",
            outline: "8px outset " + target.color,
            WebkitBoxShadow: "0px 0px 30px 4px " + target.color,
            boxShadow: "0px 0px 30px 4px " + target.color
        }}>
            {/* <div>{target.name}</div> */}
        </div>

        <div className="element-svg-text rotate" style={{
            top: (target.position.row * size) + "px",
            left: (target.position.column * size) + "px",
            width: size + "px",
            height: size + "px",
            textShadow: "0px 0px 4px black"
        }}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path
                    id="MyPath"
                    fill="none"
                    stroke="#ffffff00"
                    d="M 50 10 A 40 40 0 1 0 50 90 A 40 40 0 1 0 50 10 Z" />

                <text className="white-text">
                    <textPath href="#MyPath" >{"(❤️" + (target.maxHealth - target.damageTaken) + ") " + target.name}</textPath>
                </text>
            </svg>
        </div>
    </div>);
}

export default TargetView;