
function GameControlView({ onBackToHomePageClicked,gameAccessCode, onStartGameClicked, isGameStarted, onExitGameClicked, isGameFinished }) {


    function showGameStartButtons() {
        return <div>
            {isGameStarted ?
                <button className="animate__animated animate__fadeIn" onClick={onExitGameClicked}>Exit game</button>
                : <button className="animate__animated animate__fadeIn animate__infinite animate__slow" onClick={onStartGameClicked}>Start game</button>
            }
        </div>
    }


    return (<div className="game-control animate__animated animate__zoomIn">
        
        {!isGameFinished ?
            <div className="flex-row">
                <div style={{ color: "white" }}> Access Code: </div>
                <button id="gameIdButton">{gameAccessCode}</button>
                {showGameStartButtons()}
            </div>
            : <button className="animate__animated animate__fadeIn" onClick={onBackToHomePageClicked}>Back to home page</button>}
        
        {/* <button className="animate__animated animate__fadeIn" onClick={onExitGameClicked}>Exit game</button> */}
    </div>);
}

export default GameControlView;