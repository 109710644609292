import { useRecoilState } from "recoil";
import { artifactEnablerIdAtom, maskRadiusAtom, tankPositionAtom } from "../model/Game";
import { playerElementsIdAtom } from "../model/User";

function MaskView({ blockSize }) {
 
    const [tankPosition, setTankPosition] = useRecoilState(tankPositionAtom);
    const [maskRadius, setMaskRadius] = useRecoilState(maskRadiusAtom);
    const [playerElementsId, setPlayerElementsId] = useRecoilState(playerElementsIdAtom);
    const [artifactEnablerId, setArtifactEnablerId] = useRecoilState(artifactEnablerIdAtom);


    const translateAmount = ((maskRadius - 1) / 2) * blockSize

    return (<div className="hole" style={{
        display: (playerElementsId != artifactEnablerId) ? "block":"none",
        height: blockSize * maskRadius,
        width: blockSize * maskRadius,
        top: tankPosition.row * blockSize,
        left: tankPosition.column * blockSize,
        transform: "translateX(-" + translateAmount + "px) translateY(-" + translateAmount + "px)",
      
        
    }}>  
    </div>);
}

export default MaskView;
