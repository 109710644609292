import ArtifactView from "../views/ArtifactView";
import {  blockSizeAtom, boardColumnsCountAtom, boardRowsCountAtom } from "../model/Game";
import { useRecoilState } from "recoil";

function ArtifactPresenter() {

    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);

 

    let artifacts = [];

    for (let r = 0; r < boardRowsCount; r++) {
        for (let c = 0; c < boardColumnsCount; c++) {
            artifacts.push({
                id: r + "-" + c
            })
        }
    }

    function drawArtifact(art) {
        return <ArtifactView key={art.id}
            id={art.id}
            size={blockSize}

        ></ArtifactView>

    }


    return (<div>
        {Object.values(artifacts).map(drawArtifact)}
    </div>);
}

export default ArtifactPresenter;