import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { isPlayerLoggedInAtom, pingIntervalAtom, pingIntervalTimeAtom, socketAtom, userAccessTokenAtom, websocketUrlAtom } from '../model/User';

function WebSocketPresenter() {
    const [socket, setSocket] = useRecoilState(socketAtom);
    const [webSocketUrl, setWebSocketUrl] = useRecoilState(websocketUrlAtom);
    const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenAtom);
    const [pingInterval, setPingInterval] = useRecoilState(pingIntervalAtom);
    const [pingIntervalTime, setPingIntervalTime] = useRecoilState(pingIntervalTimeAtom);
    const [isPlayerLoggedIn, setIsPlayerLoggedIn] = useRecoilState(isPlayerLoggedInAtom);


    useEffect(() => {
        const newSocket = new WebSocket(webSocketUrl + "?token=" + userAccessToken);

        newSocket.onopen = () => {
            console.log('WebSocket connection opened');
            setPingInterval(setInterval(() => {
                if (newSocket.readyState === WebSocket.OPEN) {
                    console.log("sending ping");
                    // const message = {
                    //     "type": "ping",
                    // };
                    const message = {
                        "type": "ping"
                    };
                    newSocket.send(JSON.stringify(message));  // send a ping to keep the connection alive
                }
            }, pingIntervalTime));  // send a ping every 30 seconds
            setIsPlayerLoggedIn(true);
        }


        newSocket.onclose = (event) => {
            console.log('WebSocket connection closed:', event.code, event.reason);
            // setIsPlayerLoggedIn(false);
        }


        newSocket.onerror = (event) => {
            console.log('WebSocket connection error:', event.code, event.reason);
            setIsPlayerLoggedIn(false);
        }

        setSocket(newSocket);

        return () => {
            clearInterval(pingInterval);
            newSocket.close();
        }
    }, [isPlayerLoggedIn]);


    return (
        // render the component
        <div></div>
    );
}

export default WebSocketPresenter;