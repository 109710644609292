import { useRecoilState } from "recoil";
import { blockSizeAtom, boardColumnsCountAtom, boardRowsCountAtom, joinedPlayersAtom } from "../model/Game";
import GameEndView from "../views/GameEndView";

function GameEndPresenter() {

    // const [gameWinners, setGameWinners] = useRecoilState(gameWinnersAtom);
    // const [gameLosers, setGameLosers] = useRecoilState(gameLosersAtom);

    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [joinedPlayers, setJoinedPlayers] = useRecoilState(joinedPlayersAtom);


    const gameWinners = [];
    const gameLosers = [];

    for (let i = 0; i < joinedPlayers.length; i++) {
        const player = joinedPlayers[i];
        if (player.isAlive) {
            gameWinners.push(player);

        } else {
            gameLosers.push(player);
        }

    }


    return (<GameEndView
        width={boardColumnsCount * blockSize}
        height={boardRowsCount * blockSize}
        winnerPlayers={gameWinners}
        loserPlayers={gameLosers}
    ></GameEndView>);
}

export default GameEndPresenter;