import { useEffect } from "react";
import TankView from "../views/TankView";
import { useRecoilState } from "recoil";
import {
    blockSizeAtom, tanksAtom,
    wallsAtom, targetsAtom,
    timeBetweenActionsAtom,
    isGameStartedAtom, actionStartedAtom, shootActionAtom, tankPositionAtom
} from "../model/Game";
import { playerElementsIdAtom } from "../model/User";







function TankPresenter() {

    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [tanks, setTanks] = useRecoilState(tanksAtom); 
    const [tankPosition, setTankPosition] = useRecoilState(tankPositionAtom);
    const [playerElementsId, setPlayerElementsId] = useRecoilState(playerElementsIdAtom);







    function drawTanks(element) {
        
        if (element.ownerId == playerElementsId) {
            setTankPosition(element.position);
        }
        return <TankView key={element.id}
            size={blockSize}
            tank={element}
        ></TankView>;

    }


    return (<div>
        {Object.values(tanks).map(drawTanks)}
    </div>
    );
}

export default TankPresenter;