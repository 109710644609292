export function resolvePromise(promiseToResolve, promiseState, notify) {
  if (promiseToResolve) {
    promiseState.promise = promiseToResolve;
    promiseState.data = null; // UI update! The user does not keep seeing results from previous search
    promiseState.error = null;
    if (notify) {
      notify();
    }
    function saveDataACB(result) {
      if (promiseState.promise !== promiseToResolve) return;
      promiseState.data = result;
      if (notify) {
        notify(result);
      }
    } // triggers UI update because of changing state
    function saveErrorACB(error) {
      if (promiseState.promise !== promiseToResolve) return;
      promiseState.error = error;
      if (notify) {
        notify(error, true);
      }
    } // triggers UI update because of changing state
    promiseToResolve.then(saveDataACB).catch(saveErrorACB);
  }
}


export function treatHTTPResponseACB(response) {
  if (!response.ok) throw new Error("API problem::" + response.status);
  return response.json();
 
}

export function transformResultACB(responseJson) {
  return responseJson.results;
}

export function createCountryInfoPromise(countryName) {
  const APIUrl = "https://restcountries.com/v3.1/name/";
  const endpoint = countryName;
  const params = "";
  return fetch(
    APIUrl + endpoint + params,
    {
      // object literal
      method: "GET", // HTTP method
      // headers: {
      //   // HTTP headers, also object literal
      //   "X-Mashape-Key": API_KEY,
      //   "x-rapidapi-host":
      //     "spoonacular-recipe-food-nutrition-v1.p.rapidapi.com",
      // }, // end of headers object
    } /* end of second fetch parameter, object */
  ).then(treatHTTPResponseACB);
}

