import { useRecoilState } from "recoil";
import { blockSizeAtom, boardColumnsCountAtom, boardRowsCountAtom } from "../model/Game";
import WallView from "../views/WallView"; 
function WallPresenter() {

    // const [walls, setWalls] = useRecoilState(wallsAtom);
    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);

    let walls = [];

    for (let r = 0; r < boardRowsCount; r++) {
        for (let c = 0; c < boardColumnsCount; c++) {
            walls.push({
                id: r+ "-"+c
            })
        } 
    }

    

    function drawWalls(element) { 
        return <WallView key={element.id}
            id={element.id}
            size={blockSize} 
        > ({element.r}) </WallView>;

    }
    return (<div>
        {Object.values(walls).map(drawWalls)}</div>);
}

export default WallPresenter;