import { useRecoilState } from "recoil";
import { isPlayerLoggedInAtom, playerNameAtom, userAccessTokenAtom, userRefreshTokenAtom } from "../model/User";
import ProfileView from "../views/ProfileView";
import { setCookie } from "../model/Game";

function ProfilePresenter() {

    const [playerName, setPlayerName] = useRecoilState(playerNameAtom);
    const [isPlayerLoggedIn, setIsPlayerLoggedIn] = useRecoilState(isPlayerLoggedInAtom);
    const [userRefreshToken, setUserRefreshToken] = useRecoilState(userRefreshTokenAtom);
    const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenAtom);

    function onSignOutClicked() {
        console.log("user signed out");
        setUserAccessToken("");
        setCookie("accessToken", "", 365);
        setUserRefreshToken("");
        setCookie("refreshToken", "", 365);
        setIsPlayerLoggedIn(false);

    }

    return (<ProfileView playerName={playerName}
        onSignOutClicked={onSignOutClicked}
    ></ProfileView>);
}

export default ProfilePresenter;