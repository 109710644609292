
import TankPresenter from "./TankPresenter";
import WallPresenter from "./WallPresenter";
import TargetPresenter from "./TargetPresenter";
import BulletPresenter from "./BulletPresenter";
import DistructedPresenter from "./DistructedPresenter";
import BackgroundView from "../views/BackgroundView";
import { blockSizeAtom, boardColumnsCountAtom, boardMarginLeftAtom, boardMarginTopAtom, boardRowsCountAtom, calculateBoardMarginsAndBlockSize, isGameFinishedAtom } from "../model/Game";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import ArtifactPresenter from "./ArtifactPresenter";
import GameEndPresenter from "./GameEndPresenter";
import MaskView from "../views/MaskView";
import FreezeView from "../views/FreezeView";

function BoardPresenter() {

    const [blockSize, setBlockSize] = useRecoilState(blockSizeAtom);
    const [boardRowsCount, setBoardRowsCount] = useRecoilState(boardRowsCountAtom);
    const [boardColumnsCount, setBoardColumnsCount] = useRecoilState(boardColumnsCountAtom);
    const [boardMarginLeft, setBoardMarginLeft] = useRecoilState(boardMarginLeftAtom);
    const [boardMarginTop, setBoardMarginTop] = useRecoilState(boardMarginTopAtom);
    const [isGameFinished, setIsGameFinished] = useRecoilState(isGameFinishedAtom);
    // const [gameWinners, setGameWinners] = useRecoilState(gameWinnersAtom);
    // const [gameLosers, setGameLosers] = useRecoilState(gameLosersAtom);


    function handleScreenResize(event) {

        const result = calculateBoardMarginsAndBlockSize(event.target.innerWidth, event.target.innerHeight, boardRowsCount, boardColumnsCount)
        setBlockSize(result.blockSize);
        setBoardMarginLeft(result.marginLeft);
        setBoardMarginTop(result.marginTop);
    }


    useEffect(() => {
        window.addEventListener("resize", handleScreenResize);

        return () => {
            window.removeEventListener("resize", handleScreenResize)
        }

    }, [])




    return (
        <div>
            {isGameFinished ? <GameEndPresenter></GameEndPresenter> :
                <div className="board-holder"
                // style={{
                //     marginLeft: boardMarginLeft + "px",
                //     marginTop: boardMarginTop + "px",
                // }}
                >

                    <div id="board" className="board">
                        <BackgroundView height={boardRowsCount * blockSize} width={boardColumnsCount * blockSize}></BackgroundView>

                        <WallPresenter></WallPresenter>
                        <TargetPresenter></TargetPresenter>
                        <TankPresenter></TankPresenter>
                        <BulletPresenter></BulletPresenter>
                        <ArtifactPresenter></ArtifactPresenter>
                        <DistructedPresenter></DistructedPresenter>

                        <MaskView 
                            blockSize={blockSize}
                        ></MaskView>
                        <FreezeView 
                        ></FreezeView>
                    </div>
                </div>
            }

        </div>);
}

export default BoardPresenter;