
import './App.css';
import 'animate.css';
import HeaderPresenter from './presenter/HeaderPresenter';
import OnBoardingPresenter from './presenter/OnBoardingPresenter';
import LoggedInPresenter from './presenter/LoggedInPresenter';
import { useRecoilState } from 'recoil';
import { apiGoogleSignInUrlAtom, didReceiveGoogleAccessTokenAtom, googleAccessTokenAtom, isPlayerLoggedInAtom, playerElementsIdAtom, playerNameAtom, userAccessTokenAtom, userRefreshTokenAtom } from './model/User';
import { useEffect } from 'react';
import { auth } from './firebase/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import FooterPresenter from './presenter/FooterPresenter';
import SidebarPresenter from './presenter/SidebarPresenter';
import WebSocketPresenter from './presenter/WebSocketPresenter';
import WebsocketParserPresenter from './presenter/WebsocketParserPresenter';
import { getCookie, isGameCreatedAtom, setCookie } from './model/Game';
import { treatHTTPResponseACB } from './model/Network';
function App() {

  const [isPlayerLoggedIn, setIsPlayerLoggedIn] = useRecoilState(isPlayerLoggedInAtom);

  const [playerName, setPlayerName] = useRecoilState(playerNameAtom);
  const [playerId, setPlayerId] = useRecoilState(playerElementsIdAtom);
  const [userRefreshToken, setUserRefreshToken] = useRecoilState(userRefreshTokenAtom);
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenAtom);
  const [apiGoogleSignInUrl, setApiGoogleSignInUrl] = useRecoilState(apiGoogleSignInUrlAtom);
  const [googleAccessToken, setGoogleAccessToken] = useRecoilState(googleAccessTokenAtom);
  const [didReceiveGoogleAccessToken, setDidReceiveGoogleAccessToken] = useRecoilState(didReceiveGoogleAccessTokenAtom);
  const [isGameCreated, setIsGameCreated] = useRecoilState(isGameCreatedAtom);


  useEffect(() => {
    let accessToken = getCookie("accessToken");
    if (accessToken != "") {

      // alert("Welcome again " + accessToken);
      setUserAccessToken(accessToken);
      setIsPlayerLoggedIn(true);
    } else {
      setIsPlayerLoggedIn(false);
    }

  }, []);


  useEffect(() => {

    if (didReceiveGoogleAccessToken) {

      fetch(apiGoogleSignInUrl, {
        method: "POST",
        headers: { 'token': googleAccessToken },
        // body: JSON.stringify({
        //     name: "Deska",
        //     email: "deska@gmail.com",
        //     phone: "342234553"
        // })
      }).then(treatHTTPResponseACB).then(result => {
        // do something with the result
        // const ob = JSON.parse(result.json);
        console.log("Completed with result:", result);
        if (result.accessToken) {
          setUserAccessToken(result.accessToken);
          setCookie("accessToken", result.accessToken, 365);
          setIsPlayerLoggedIn(true);
        }
        if (result.refreshToken) {
          setUserRefreshToken(result.refreshToken);
          setCookie("refreshToken", result.refreshToken, 365);
        }
      }).catch(err => {
        // if any error occured, then catch it here
        console.error(err);
        // setIsPlayerLoggedIn(false);
      });
    }


  }, [didReceiveGoogleAccessToken]);





  return (
    <div className="App app">
      <WebSocketPresenter></WebSocketPresenter>
      <WebsocketParserPresenter></WebsocketParserPresenter>
      <HeaderPresenter></HeaderPresenter>
      <div className='content'>
        {isPlayerLoggedIn ? <LoggedInPresenter /> : <OnBoardingPresenter />}
      </div>
      <FooterPresenter></FooterPresenter>
    </div >
  );
}

export default App;
