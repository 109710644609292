import { useRecoilState } from "recoil";
import { artifactsAtomFamily } from "../model/Game";

function ArtifactView({ id, size }) {

    const [artifact, setArtifact] = useRecoilState(artifactsAtomFamily(id));

    if (!artifact) {
        return <div></div>;
    }
    // console.log("artifact is:", artifact);

    const image = process.env.PUBLIC_URL + "images/" + artifact.image;
    return (
        <div className="animate__animated animate__fadeIn ">
            <div className={"artifact "} style={{
                top: (artifact.position.row * size) + "px",
                left: (artifact.position.column * size) + "px",
                width: size + "px",
                height: size + "px",
                backgroundImage: "url(" + image + ")",
                animationDelay: artifact.timeUntilDeath - 1 + "s"
            }} >
                {artifact.name}
            </div>
        </div>);
}

export default ArtifactView;