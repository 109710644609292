import { useRecoilState } from "recoil";
import { artifactEnablerIdAtom, freezeTimeAtom, maskRadiusAtom, tankPositionAtom } from "../model/Game";
import { playerElementsIdAtom } from "../model/User";

function FreezeView() {
  
          const [freezeTime, setFreezeTime] = useRecoilState(freezeTimeAtom);
          const [playerElementsId, setPlayerElementsId] = useRecoilState(playerElementsIdAtom);
    const [artifactEnablerId, setArtifactEnablerId] = useRecoilState(artifactEnablerIdAtom);

 
          console.log("freeze time is", freezeTime, " Players elements id is: ", playerElementsId);
    return (<div className="freeze-view" style={{
              display: (playerElementsId != artifactEnablerId) ? "flex" : "none",
          //     display: "block",  
              transform: "scale("+ freezeTime/5+")"
        
    }}>  
              <div className="freeze-image"></div>
              <div>{ freezeTime}</div>
    </div>);
}

export default FreezeView;
