import { useRecoilState } from "recoil";
import NewlyCreatedGamesView from "../views/NewlyCreatedGamesView";
import { gameIdAtom, newlyCreatedGamesAtom } from "../model/Game";
import { useEffect } from "react";

function NewlyCreatedGamesPresenter() {

    const [newlyCreatedGames, setNewlyCreatedGames] = useRecoilState(newlyCreatedGamesAtom);
    const [gameId, setGameId] = useRecoilState(gameIdAtom);

    function onGameIdClicked(event, game) {
        event.preventDefault();
        setGameId(game.accessCode);
        document.getElementById("joinInputId").value = game.id;
    }

    useEffect(() => {
        // DO NOT REMOVE
        // updates the game id which is copied from the newly created games
        // console.log(""); 
    }, [gameId])


    return (<NewlyCreatedGamesView
        onGameIdClicked={onGameIdClicked}
        accessCodes={newlyCreatedGames}
    ></NewlyCreatedGamesView>);
}

export default NewlyCreatedGamesPresenter;