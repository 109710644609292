import { useRecoilState } from "recoil";
import { shootActionAtom, actionStartedAtom, gameAccessCodeAtom, gameIdAtom, isGameCreatedAtom, isGameFinishedAtom, isGameStartedAtom, orientations, timeBetweenActionsAtom, artifactEnablerIdAtom, freezeTimeAtom } from "../model/Game";
import GameControlView from "../views/GameControlView";
import { removePlayer } from "../firebase/firebase";
import { playerElementsIdAtom, socketAtom } from "../model/User";
import { useEffect } from "react";

function GameControlPresenter() {

    const [gameId, setGameId] = useRecoilState(gameIdAtom);
    const [playerId, setPlayerId] = useRecoilState(playerElementsIdAtom);
    const [isGameStarted, setIsGameStarted] = useRecoilState(isGameStartedAtom);
    const [isGameCreated, setIsGameCreated] = useRecoilState(isGameCreatedAtom);
    const [isGameFinished, setIsGameFinished] = useRecoilState(isGameFinishedAtom);
    const [socket, setSocket] = useRecoilState(socketAtom);
    const [actionStarted, setActionStarted] = useRecoilState(actionStartedAtom);
    const [action, setAction] = useRecoilState(shootActionAtom);
    const [timeBetweenActions, setTimeBetweenActions] = useRecoilState(timeBetweenActionsAtom);


    const [gameAccessCode, setGameAccessCode] = useRecoilState(gameAccessCodeAtom);

    function onStartGameClicked() {
        console.log("Game is started");
        document.getElementById("gameIdButton").focus();
        // setIsGameStarted(true);
        // uploadIsGameStarted(gameId, { isGameStarted: true });

        const message = {
            "type": "startGame",
            "gameId": gameId
        };
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        }

    }

    function onExitGameClicked() {
        console.log("Game is exited");


        const message = {
            "type": "exitGame",
            "gameId": gameId
        };
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        }

        // removePlayer(gameId, playerId);
        setIsGameStarted(false);
        setIsGameCreated(false);
        setIsGameFinished(true);
        setGameId(0);

    }
    function onBackToHomePageClicked() {
        console.log("Game home clicked");

        setIsGameStarted(false);
        setIsGameCreated(false);
        setIsGameFinished(false);
        setGameId(0);

    }


    function moveTank(movingOrientation) {

        // console.log("moving... ", movingOrientation);
        const message = {
            "type": "move",
            "gameId": gameId,
            "orientation": movingOrientation
        };
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        }

    }

    function shootBullet() {

        // console.log("shooting...");
        const message = {
            "gameId": gameId,
            "type": "shoot",
        };
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
            const shootAudio = process.env.PUBLIC_URL + "sounds/shoot.wav";
            const shootAudioPlayer = new Audio(shootAudio);
            shootAudioPlayer.volume = 0.05;
            shootAudioPlayer.play();
        }

    }

          function performAction() {

           
        switch (action) {
            case "shoot":
                shootBullet();
                break;
            case "up":
                moveTank(orientations.up);
                break;
            case "down":
                moveTank(orientations.down);
                break;
            case "left":
                moveTank(orientations.left);
                break;
            case "right":
                moveTank(orientations.right);
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (!isGameStarted) {
            return;
        }
        let intervalReference = undefined;
        if (actionStarted) {
            performAction();
            intervalReference = setInterval(() => {
                performAction();
            }, timeBetweenActions);
        } else {
            if (intervalReference) {
                clearInterval(intervalReference);
            }
        }

        return () => {
            clearInterval(intervalReference);
        }
    }, [actionStarted]);




    return (<GameControlView
        onBackToHomePageClicked={onBackToHomePageClicked}
        gameAccessCode={gameAccessCode}
        onStartGameClicked={onStartGameClicked}
        isGameStarted={isGameStarted}
        isGameFinished={isGameFinished}
        onExitGameClicked={onExitGameClicked}
    ></GameControlView>);
}

export default GameControlPresenter;